import BaseService from '@/services/BaseService'

class UserSettings extends BaseService {
  constructor() {
    super()
  }
  async getUser(params = {}) {
    this.endPoint = 'api/v2/users'
    return this.get(params)
  }

  async getUsers(params = {}) {
    // this.endPoint = 'users'
    this.endPoint = 'api/v2/users'
    return this.get(params)
  }

  async getInactiveUsers(params = {}) {
    this.endPoint = 'api/v2/users/inactive'
    return this.get(params)
  }

  // old
  // async getOneUser(id: any, params = {}) {
  //   // this.endPoint = 'api/v2/users/'+id+'?include=codes'
  //   this.endPoint = 'api/v2/users'
  //   return this.getOne(id, params)
  // }

  // new
  async getOneUser(id: any, params = {}) {
    this.endPoint = 'api/v2/users'
    return this.getOne(id, params)
  }

  async getOneUserWithInclude(id: any, params = {}) {
    // this.endPoint = `api/v2/users`
    this.endPoint = 'api/v2/users/' + id + '?include=codes,memberships'
    // return this.getOne(params)
    return this.get(params)
  }

  async getOneUserCart(id: any, params = {}) {
    // this.endPoint = 'api/v2/users/'+id+'?include=codes'
    this.endPoint = 'api/v2/users'
    return this.getOne(id, params)
  }

  async createUser(data: any) {
    // this.endPoint = 'auth/register'
    this.endPoint = 'api/v2/users'
    return this.post(data)
  }

  async update(id: any, data: any) {
    // this.endPoint = 'users'
    this.endPoint = 'api/v2/users'
    return this.putOne(id, data)
  }

  async getRoles(params = {}) {
    this.endPoint = 'roles'
    return this.get(params)
  }

  async getCategories() {
    this.endPoint = 'categories'
    return this.get()
  }

  async getMemberships(params = {}) {
    this.endPoint = 'api/v2/memberships'
    return this.get(params)
  }

  async getLocalResellerPlus(params = {}) {
    this.endPoint = 'api/v2/memberships/21'
    return this.get(params)
  }

  async updateMembershipLimit(id, data: any) {
    this.endPoint = `api/v2/memberships/${id}`
    return this.put(data)
  }

  async getTotalProducts(id, opts) {
    this.endPoint = `/api/v2/users/${id}/my-products`
    return this.get(opts)
  }

  async getAllTotalProducts() {
    this.endPoint = 'api/v2/users/total-my-products'
    return this.get()
  }

  async deleteUsers(id) {
    this.endPoint = 'api/v2/users'
    return this.delete(id)
  }

  async restoreUser(data: any) {
    this.endPoint = 'api/v2/users/restore'
    return this.post(data)
  }

  async getContactUser(userid, opts) {
    this.endPoint = `api/v2/users/${userid}/contacts`
    return this.get(opts)
  }

  async getOneContact(userid, contactid, opts = {}) {
    this.endPoint = `api/v2/users/${userid}/contacts`
    return this.getOne(contactid, opts)
  }

  async setContact(userid, data: any) {
    this.endPoint = `api/v2/users/${userid}/contacts`
    return this.post(data)
  }

  async updateContact(userid, contactid, data: any) {
    this.endPoint = `api/v2/users/${userid}/contacts`
    return this.putOne(contactid, data)
  }

  async deleteContact(userid, contactid) {
    this.endPoint = `api/v2/users/${userid}/contacts`
    return this.delete(contactid)
  }

  async addCompany(data: any, params = {}) {
    this.endPoint = 'api/v2/companies'
    return this.post(data, params)
  }

  async updateCompany(id, data: any, params = {}) {
    this.endPoint = `api/v2/companies/${id}`
    return this.post(data, params)
  }

  async documents(id, data: any) {
    this.endPoint = `api/v2/users/${id}/document-verification`
    return this.post(data)
  }
}

export default new UserSettings()
