import BaseService from '@/services/BaseService'

class UserPayments extends BaseService {
  constructor() {
    super()
  }

  async getPaymentPlatforms(params = {}) {
    this.endPoint = 'api/v2/payment-platforms'
    return this.get(params)
  }

  async setUserPayments(payload) {
    this.endPoint = 'api/v2/user-payments'
    return this.post(payload)
  }

  async getOneUserPayments(id: any, params = {}) {
    this.endPoint = 'api/v2/user-payments'
    return this.getOne(id, params)
  }
}

export default new UserPayments()
