import BaseService from '@/services/BaseService'

class BalanceHistory extends BaseService {
  constructor() {
    super()
  }

  async getBalanceHistory(params = {}) {
    this.endPoint = 'api/v2/payout-commision'
    return this.get(params)
  }

  async setBalanceHistory(payload) {
    this.endPoint = 'api/v2/payout-commision'
    return this.post(payload)
  }

  async putBalanceHistory(id, payload) {
    this.endPoint = 'api/v2/payout-commision'
    return this.putOne(id, payload)
  }

  async deleteBalanceHistory(id) {
    this.endPoint = 'api/v2/payout-commision'
    return this.delete(id)
  }
}

export default new BalanceHistory()
